import classNames from 'classnames'
import Link from 'next/link'
import { useState } from 'react'
import { ReactNode } from 'react'
import BSCard from 'react-bootstrap/Card'

import Card from '@/components/Card/Card'
import Icon from '@/components/Icon/Icon'
import { useCard } from '@/utils/fetcher'
import { formatUrl } from '@/utils/helpers'

import styles from './CardHover.module.scss'

type Props = {
  children?: ReactNode | undefined
  name: string
  left?: boolean
  mobileOnly?: boolean
  routerUnavailable?: boolean
  url: string
  isPreview?: boolean
}

const CardHover = ({ children, left, name, mobileOnly, routerUnavailable, url, isPreview }: Props) => {
  const [isHovering, setIsHovering] = useState(false)
  const [mobileViewerIsOpen, setMobileViewerIsOpen] = useState(false)
  const childrenOrName = children || name

  // Fetch data
  // @ts-ignore
  const { data: fetchedData, error } = useCard({ undefined, name })
  const data = fetchedData

  return (
    <>
      <span
        className={classNames(`d-none d-md-inline`, {
          [styles.error]: isPreview && error,
          [styles.typo]: isPreview && data?.typo && !error,
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <span>
          {url ? (
            <Link href={`/${data?.legal_commander ? 'commanders' : 'cards'}/${formatUrl(name.split('|')[0])}`}>
              {childrenOrName}
            </Link>
          ) : (
            childrenOrName
          )}
        </span>
        {isHovering && !mobileOnly ? (
          <span className={classNames(styles.hover, { [styles.left]: left }, 'shadow-sm')}>
            <BSCard>
              <Card background name={name} routerUnavailable={routerUnavailable} isPreview={isPreview} />
            </BSCard>
          </span>
        ) : null}
      </span>
      <span className='d-inline d-md-none'>
        <span className='fake-link' onClick={() => setMobileViewerIsOpen(true)}>
          {childrenOrName}
        </span>
        {mobileViewerIsOpen && (
          <div className={styles.mobileViewer} onClick={() => setMobileViewerIsOpen(false)}>
            <div className='align-items-end d-flex flex-column'>
              <div className='position-relative'>
                <Icon className={classNames(styles.close, 'cursor-pointer text-danger')} icon='times' size='xl' />
              </div>
              <div onClick={(event) => event.stopPropagation()}>
                <Card background name={name} routerUnavailable={routerUnavailable} isPreview={isPreview} />
              </div>
            </div>
          </div>
        )}
      </span>
    </>
  )
}

export default CardHover
