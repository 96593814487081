import classNames from 'classnames'
import Link from 'next/link'
import { Fragment } from 'react'
import Card from 'react-bootstrap/Card'

import { ArticleType } from '@/types/home'
import { ArticleListEntryType } from '@/types/page'
import { extractSlug } from '@/utils/helpers'

import styles from './ArticlePreview.module.scss'

type Props = {
  article: ArticleType | ArticleListEntryType
  small?: boolean
  square?: boolean
}

const ArticlePreview = ({ article, small, square }: Props) => {
  const { author, date, excerpt, link, media, site, tags, title, href, value } = article

  const articleLink = `${extractSlug(link || href)}`
  if (!articleLink) return null
  let authorName = author?.name
  if (site && site.id !== 'edhrec') {
    authorName += ` — ${site.name}`
  }

  return (
    <div className={styles.container}>
      <Card className={classNames('h-100 m-0 shadow-sm', { [styles.small]: small, 'rounded-0': square })}>
        <Link href={articleLink}>
          <Card.Img className={classNames(styles.media, { 'rounded-0': square })} variant='top' src={media} />
        </Link>
        <Card.Body className='d-flex flex-column'>
          {author ? (
            <Link href={author.link}>
              <div className={styles.avatar}>
                <img alt={author.name} src={author.avatar} />
              </div>
            </Link>
          ) : null}
          <div className='d-flex flex-column justify-content-between'>
            <div className={styles.body}>
              <div className='align-items-center d-flex justify-content-between'>
                <div className={styles.date}>{date}</div>
                <div className={styles.author}>
                  {author ? (
                    <Link dangerouslySetInnerHTML={{ __html: authorName }} href={`${extractSlug(author.link)}`} />
                  ) : null}
                </div>
              </div>
              <h5 className={styles.title}>
                <Link href={articleLink}>{title || value}</Link>
              </h5>
              <div className={styles.excerpt}>{excerpt}</div>
            </div>
            {tags && tags.length > 0 && (
              <div className='d-none d-md-block'>
                <hr />
                <div className={styles.tags}>
                  {tags.map(({ link, name }, i) => (
                    <Fragment key={link}>
                      <Link href={`${extractSlug(link)}`}>{`#${name}`}</Link>
                      {i !== tags.length - 1 && <span>, </span>}
                    </Fragment>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default ArticlePreview
